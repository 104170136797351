html, body {
    background-color: $background; }

html.modal-open {
    overflow: hidden; }

@keyframes fadeIn {
    from {
        opacity: 0; }
    to {
        opacity: 1; } }

.fade-in {
    opacity: 0;
    animation: fadeIn ease-in 1;
    animation-fill-mode: forwards;
    animation-duration: 1s;
    &.one {
        animation-delay: 0.7s; }
    &.two {
        animation-delay: 1.4s; }
    &.three {
        animation-delay: 1.8s; } }

a {
    color: $primary;
    &:hover {
        color: $link-hover;
        font-style: none; }
    &:active {
        color: $primary; } }

h1, h2, .title, .subtitle {
    color: $h-color; }

.title {
    font-weight: 400; }

ul {
    padding: 0; }

img {
    border-radius: 5px;
    border: 1px solid $secondary;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4); }

hr {
    background-color: #dbdbdb;
    height: 1px; }

.container {
    max-width: 1000px; }

.noborder {
    border-radius: 0px;
    border: none;
    box-shadow: none; }

.hidden {
    display: none; }

pre {
    background-color: #eee; }

.img-responsive {
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4); }

.avatar {
    border: none;
    @include mobile {
        max-width: 50%; } }

.thumbnail {
    border: none; }

.bold-title {
    font-size: 6rem;
    line-height: 1.2;
    margin-bottom: 0.25em;
    @include mobile {
        font-size: 3rem;
        text-align: center; } }

.top-pad {
    padding-top: 1rem; }

.bottom-pad {
    padding-bottom: 1rem; }

.strong-post-title {
    font-weight: $weight-bold; }

.post-item {
    display: block;
    list-style: none;
    list-style-position: outside;
    margin-left: 0; }

.post-data {
    font-size: 1rem;
    line-height: 2rem; }

.social-icons {
    padding: 0 10px;
    a {
        margin: 0 5px; } }

.icon {
    height: 2rem;
    width: 2rem;
    margin: 0 10px; }

.fab {
    font-size: 21px; }

.blog-share {
    .icon {
        height: 1rem;
        width: 1rem;
        vertical-align: baseline;
        margin: 0 5px; } }

.navbar-burger {
    margin-right: auto; }

.navbar-burger:hover {
    background-color: $navbar-background-color; }

.navbar-item {
    text-transform: uppercase;
    font-size: 14px; }

.owl-nav {
    height: 50px; }

.owl-next, .owl-prev {
    height: 30px;
    span {
        font-size: 3rem;
        line-height: 30px; } }

.footer-text {
    font-size: 0.8em;
    a {
        color: $body-color; }
    .fab {
        font-size: 0.8em;
        vertical-align: baseline; } }

.tags-list {
    width: 70%;
    margin: 0 auto;
    text-align: center;
    font-size: 1.5rem;
    @include mobile {
        width: 100%; } }

.tag-cloud {
    font-size: 1.5rem;
    margin-right: 1.5rem;
    @include mobile {
        font-size: 1.5rem;
        margin-right: 1rem; } }

.card {
    box-shadow: none; }

.card-content {
    background-color: $background;
    font-size: 1.5rem; }

.has-content-centered {
    justify-content: center; }

.markdown {
    p {
        margin-bottom: 1em; }
    h1 {
        font-size: $size-1;
        @include mobile {
            font-size: $size-2; } }
    h2 {
        font-size: $size-2;
        line-height: 1em;
        margin-top: 1em;
        margin-bottom: 0.5em;
        @include mobile {
            font-size: $size-3; } }
    h3 {
        font-size: $size-3;
        @include mobile {
            font-size: $size-4; } }
    h4 {
        font-size: $size-4;
        @include mobile {
            font-size: $size-5; } }
    h5 {
        font-size: $size-5; }
    h6 {
        font-size: $size-6; }
    a {
        &:hover {
            color: $secondary; } }
    ul {
        margin-bottom: 1.25rem;
        margin-left: 1.5em;
        list-style: disc; }
    ul ul {
        margin-bottom: 0; }
    li {
        margin-left: 1.5em;
        list-style-position: outside;
        padding-left: 1em; }
    ol {
        margin-bottom: 1.25rem;
        margin-left: 1.5em; }
    ol ol {
        list-style-type: lower-alpha;
        margin-bottom: 0; }
    ol ol ol {
        list-style-type: lower-roman; }
    em {
        font-style: italic; }
    strong {
        font-weight: 700; }
    hr {
        position: relative;
        margin: 1.75rem 0;
        border: 0;
        border-top: 1px solid $secondary; }
    abbr {
        font-size: 0.8rem;
        font-weight: bold;
        color: #666666;
        text-transform: uppercase; }
    abbr[title] {
        cursor: help;
        border-bottom: 1px dotted #808080; }
    blockquote {
        padding: .5rem 1rem;
        margin: .8rem 0;
        color: #7a7a7a;
        border-left: .25rem solid #e5e5e5;
        blockquote p:last-child {
            margin-bottom: 0; } }
    table {
        margin: 2em 0 2em 0;
        width: 100%;
        border: 1px solid #e5e5e5;
        border-collapse: collapse; }
    td, th {
        padding: .25rem .5rem;
        border: 1px solid #e5e5e5;
        text-align: center;
        background-color: #f7f7f7; }
    tbody tr:nth-child(odd) td,
    tbody tr:nth-child(odd) th {
        background-color: darken(#f7f7f7, 10%); }
    tbody tr:nth-child(even) td,
    tbody tr:nth-child(even) th {
        background-color: #f7f7f7; }
    pre {
        border-radius: 3px; }
    img {
        display: block;
        margin: 2rem auto;
        max-width: 100%; } }
